<template>
	<div style="background-color: #EBEEF5;padding-bottom: 10px;">
		<div class="sameWidth">
			<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0px 0 0px;">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item>人力资源</el-breadcrumb-item>
			</el-breadcrumb>
			<div
				style="margin:20px 0 0 0px;text-align:left;display: table;width: 100%;padding:0;color: #595959;background-color: #fff;">
				<el-tabs type="border-card" style="height: 500px;">
					<el-tab-pane label="招聘信息">
						<h4>公司长期招聘业界精英、事业合伙人/分公司负责人、管培生等，期待加入，共创伟业！<br>最新相关信息，可通过以下两种方式获取/联系：</h4>
						<h4 style="padding-left: 20px;">1、招聘邮箱：38236981@qq.com（请写明姓名+应聘岗位）</h4>
<!--						<h4 style="padding-left: 20px;margin-top: -10px;">2、招聘电话：020-34667273</h4>-->
					</el-tab-pane>
					<el-tab-pane label="离职信息">
						<h4>公司非常重视团队诚信建设，积极参与行业自律，如需了解离职、解雇、失信和除名详细信息，请发送邮件至38236981@qq.com咨询。</h4>
						<h4 style="padding-left: 20px;">联系部门：人力资源中心</h4>
<!--						<h4 style="padding-left: 20px;margin-top: -10px;">联系电话：020-34667273</h4>-->
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

	</div>
</template>


<style scoped>
	.contentBan {
		padding-left: 20px;
	}
</style>
<script>
	export default {
		name: 'manage',
		data() {
			return {
				screenWidth: 1300,
			}
		},
		methods: {},
		mounted: function() {
			this.bus.$emit('headerImg', [{
				src: require('@/assets/images/bk/专家中心.jpg'),
				href: ""
			}]);
			this.screenWidth = document.documentElement.clientWidth;
			window.addEventListener('resize', () => {
				this.screenWidth = document.documentElement.clientWidth;
			})
		}
	};
</script>
